import React, { useState } from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import { Blocks, ErrorMsg, Header, SEO } from "@shared";
import { usePageMeta } from "@util/logicHooks";
import VillageSearchWrapper from "@components/search/villageSearchWrapper";
import VillageHits from "@components/village/villageHits";
import VillageFilters from "@components/village/villageFilters";
import { Configure } from "react-instantsearch-dom";
import {
  PageContentWrapper,
  StickyWrapper,
  VillageHitsWrapper,
} from "@styles/villageTemplateStyles";

interface Props extends PageProps {
  data: Query;
  pageContext: { isNewDevelopment?: boolean };
}

export default function VillagesLandingTemplate({
  data: {
    sanityVillagesLanding,
    sanityNewDevelopmentLanding,
    sanityHeader,
    allSanityVillage: { nodes },
  },
  pageContext,
  ...rest
}: Props) {
  if (sanityVillagesLanding == null && sanityNewDevelopmentLanding == null)
    return <ErrorMsg data={sanityVillagesLanding} msg="Error fetching data for page" />;

  const [hitsLength, setHitsLength] = useState(0);
  const { isNewDevelopment } = pageContext;

  const pageData = isNewDevelopment ? sanityNewDevelopmentLanding : sanityVillagesLanding;
  if (pageData == null) return null;

  const { slug, _type, pageColour, title, seo, blocks, subtitle } = pageData;
  usePageMeta(pageColour?.colour?.hex, _type);

  return (
    <div>
      <SEO {...rest} seoData={seo} slug={slug?.current as string} />
      <Header data={sanityHeader} />
      <VillageSearchWrapper
        indexName="villages"
        title={title}
        subTitle={subtitle}
        hideMapButton={isNewDevelopment}
        config={
          <Configure
            hitsPerPage={1000}
            filters={isNewDevelopment ? `filters.typeOfVillage.title: "New development"` : ""}
          />
        }
      >
        <PageContentWrapper>
          <StickyWrapper>
            <VillageFilters
              hitsLength={hitsLength}
              defaultLength={nodes.length}
              isNewDevelopment={isNewDevelopment}
            />
          </StickyWrapper>
          <VillageHitsWrapper width="70%" tabletWidth="100%" backgroundColor="white">
            <VillageHits defaultData={nodes} setHitsLength={setHitsLength} gridRepeat={2} />
          </VillageHitsWrapper>
        </PageContentWrapper>
      </VillageSearchWrapper>
      {blocks && <Blocks data={blocks} />}
    </div>
  );
}

export const query = graphql`
  query villagesLandingQuery($isNewDevelopment: Boolean) {
    sanityHeader {
      ...sanityHeader
    }
    sanityVillagesLanding {
      _type
      title
      subtitle
      slug {
        current
      }
      seo {
        ...sanitySeo
      }
      pageColour {
        ...sanityColorPicker
      }
      blocks {
        ...staticPageBlocks
      }
    }
    allSanityVillage(
      filter: { isDevelopment: { eq: $isNewDevelopment } }
      sort: { order: ASC, fields: title }
    ) {
      nodes {
        ...sanityVillageMeta
      }
    }
    sanityNewDevelopmentLanding {
      _type
      title
      slug {
        current
      }
      seo {
        ...sanitySeo
      }
      pageColour {
        ...sanityColorPicker
      }
      blocks {
        ...staticPageBlocks
      }
    }
  }
`;
